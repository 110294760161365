<script lang="ts" setup>
onBeforeUnmount(() => {
  // document.head.querySelector('[data-vite-dev-id=]')
});
</script>

<template>
  <AppHeader />
  <main id="theme-app">
    <slot />
  </main>
  <AppFooter />
</template>

<style scoped lang="scss">
#theme-app {
  &:deep {
    @import "~/pages/landing/mobile-app-1/assets/scss/theme.scss";
  }
}
</style>
